import { ImageAttributes } from "./arrayInterface"

const DigitalnaArr: Array<ImageAttributes> = [
  {
    src: '/assets/digital/mormon.jpg',
    title: 'Atlantski mormon',
    sciName: 'Fratercula arctica',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/skoljka.jpg',
    title: 'Školjka',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Letni casi.jpg',
    title: 'Letni časi',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Hrast.PNG',
    title: 'Hrast',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Zelod.PNG',
    title: 'Želod',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Mlada_svinja.PNG',
    title: 'Mladič divje svinje',
    sciName: 'Sus scrofa',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Mesto.jpg',
    title: 'Mesto',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Lastovicar.jpg',
    title: 'Lastovičar',
    sciName: 'Papilio machaon',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Gosenica_lastovicarja.jpg',
    title: 'Gosenica lastovičarja',
    sciName: 'Papilio machaon',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Planinski_zajec_(zima).jpg',
    title: 'Planinski zajec',
    sciName: 'Lepus timidus',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Nastanek_gora.jpg',
    title: 'Gore',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Triasno_morje.jpg',
    title: 'Triasno morje',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Premog.jpg',
    title: 'Premog',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Karbonski_gozd.jpg',
    title: 'Karbonski gozd',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Kameleon.jpg',
    title: 'Kameleon',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/tiranozaver_okostje.png',
    title: 'Okostje tiranozavra',
    sciName: 'Tiranozaver rex',
    description: 'Digitalna ilustracija',
  },
  ////

  {
    src: '/assets/digital/lj_barje_cas.jpg',
    title: 'Ljubljansko barje skozi čas',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/zivljenje_jarek.jpg',
    title: 'Življenje v jarku',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/ojezeritev_barja.jpg',
    title: 'Ojezeritev Ljubljanskega barja',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/prerez_šotnih_tal.jpg',
    title: 'Prerez šotnih tal',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Tiranozaver.jpg',
    title: 'Tiranozaver',
    sciName: 'Tiranozaver rex',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Gozd.png',
    title: 'Gozd',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Merjasec.PNG',
    title: 'Divja svinja',
    sciName: 'Sus scrofa',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/Pomlad_.jpg',
    title: 'Pomlad',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/monarh_gosenica.jpg',
    title: 'Gosenica monarha',
    sciName: 'Danaus plexippus',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/monarh_naveji.jpg',
    title: 'Monarh metulj',
    sciName: 'Danaus plexippus',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/krog_pupek.jpeg',
    title: 'Življenski krog repatih dvoživk',
    description: 'Digitalna ilustracija',
  },

  {
    src: '/assets/digital/krog_zaba.jpeg',
    title: 'Življenski krog brezrepih dvoživk',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/monarh.jpeg',
    title: 'Monarh',
    sciName: 'Danaus plexippus',
    description: 'Digitalna ilustracija ',
  },

  {
    src: '/assets/digital/tascica.jpeg',
    title: 'Taščica',
    sciName: 'Erithacus rubecula',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/oljka_digital.jpeg',
    title: 'Oljka',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/borovnice.jpeg',
    title: 'Borovnica',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/lilija.jpeg',
    title: 'Lilija',
    description: 'Digitalna ilustracija',
  },
  {
    src: '/assets/digital/jabolko.jpeg',
    title: 'Jabolko in mleko',
    description: 'Digitalna ilustracija ',
  },
  {
    src: '/assets/digital/kresnicke.jpeg',
    title: 'Kresničke',
    description: 'Digitalna ilustracija ',
  },
]

export default DigitalnaArr
